<template>
<div class="container page-performance-details">
  <div class="card">
    <spinner v-if="!unsecuredLoans" />
    <section v-else>
      <h2 class="m-b-0">Unsecured Loans</h2>
      <v-client-table
        :data="unsecuredLoans"
        :columns="banksTableColumns"
        :options="banksTableOptions"
      >
        <template v-slot:fullBankName="data">
          <div class="bank-logo m-b-5">
            <bank-logo class="logo" :bank-name="data.row.bankName" :alt="data.row.fullBankName" />
          </div>
          <b>{{ data.row.fullBankName }}</b>
        </template>
        <template v-slot:amountMin="data">
          <template v-if="data.row.amountMin === null">N/A</template>
          <template v-else><currency-symbol />{{ data.row.amountMin | commarize }}</template>
        </template>
        <template v-slot:amountMax="data">
          <template v-if="data.row.amountMax === null">N/A</template>
          <template v-else><currency-symbol />{{ data.row.amountMax | commarize }}</template>
        </template>
        <template v-slot:apr="data">
          <template v-if="data.row.apr === null">N/A</template>
          <template v-else>{{ data.row.apr }}%</template>
        </template>
        <template v-slot:loanTermMin="data">
          {{ loanTermMaxDic[data.row.loanTermMin] || '' }}
        </template>
        <template v-slot:loanTermMax="data">
          {{ loanTermMaxDic[data.row.loanTermMax] || '' }}
        </template>
        <template v-slot:hasEarlyRepaymentFee="data">
          <template v-if="data.row.hasEarlyRepaymentFee === null">N/A</template>
          <icon
            :class="data.row.hasEarlyRepaymentFee ? 'text-primary' : 'text-danger'"
            :name="data.row.hasEarlyRepaymentFee ? 'check' : 'times'"
            v-else
          />
        </template>
        <template v-slot:hasArrangementFee="data">
          <template v-if="data.row.hasArrangementFee === null">N/A</template>
          <icon
            :class="data.row.hasArrangementFee ? 'text-primary' : 'text-danger'"
            :name="data.row.hasArrangementFee ? 'check' : 'times'"
            v-else
          />
        </template>
        <template v-slot:action="data">
          <router-link
            class="btn btn-primary btn-sm"
            :to="{ name: 'matches-deal', params: { opportunityId: data.row.opportunityId } }"
          >Apply</router-link>
        </template>
      </v-client-table>
    </section>
  </div>
</div>
</template>

<script>
import BankLogo from '@/components/BankLogo'
import { getLoansBankComparison } from '@/api/openBankingOpenData'
import { loanTermMaxDic } from '@/utils/constants'
import Vue from 'vue'
import { ClientTable } from 'vue-tables-2'
import vueTablesConf from '@/config/vue-tables'

Vue.use(ClientTable, vueTablesConf.options, vueTablesConf.useVuex, vueTablesConf.theme, vueTablesConf.template)

export default {
  components: {
    BankLogo
  },
  data () {
    return {
      loanTermMaxDic,
      unsecuredLoans: null,
      banksTableColumns: ['fullBankName', 'amountMin', 'amountMax', 'apr', 'loanTermMin', 'loanTermMax', 'hasEarlyRepaymentFee', 'hasArrangementFee', 'action'],
      banksTableOptions: {
        uniqueKey: 'bankName',
        skin: 'table table-v-align swoop-grid',
        filterable: false,
        headings: {
          fullBankName: '',
          amountMin: 'MIN Amount',
          amountMax: 'MAX Amount',
          apr: 'APR',
          loanTermMin: 'MIN Loan Term',
          loanTermMax: 'MAX Loan Term',
          hasEarlyRepaymentFee: 'Early Repayment Fee',
          hasArrangementFee: 'Arrangement Fee'
        },
        sortable: [],
        perPage: 100
      }
    }
  },
  methods: {
    fillUnsecuredLoans () {
      return getLoansBankComparison()
        .then(res => {
          this.unsecuredLoans = res.data
        })
        .catch(() => {
          this.unsecuredLoans = []
        })
    }
  },
  beforeMount () {
    this.fillUnsecuredLoans()
  }
}
</script>

<style lang="scss" scoped>
@import '../../../assets/styles/swoop/variables';

.page-performance-details {
  flex-grow: 1;
}

.VueTables {
  max-width: 100%;
  /deep/ {
    td,
    th {
      text-align: center;
      &:first-child,
      &:last-child {
        text-align: left;
      }
    }
    .VuePagination,
    .VueTables__limit {
      display: none;
    }
    .bank-logo {
      .logo {
        display: block;
        max-width: 100px;
        max-height: 48px;
      }
    }
  }
}
</style>
